/* Source: src/mt-includes/widgets/grid_gallery/frontend.js*/
angular.module('website.widget.grid_gallery', [])
    .directive('motoGridGalleryOptions', function() {
        return {
            restrict: 'A',
            priority: 450,
            link: function($scope, $element, $attrs) {
                var options = $scope.$eval($attrs.motoGridGalleryOptions);

                $element.justifiedGallery({
                    rowHeight: options.rowHeight,
                    maxRowHeight: (options.fixedHeight) ? options.rowHeight : 0,
                    margins: options.margins,
                    lastRow: options.lastRow,
                    captions: options.showCaptions,
                    cssAnimation: true
                });
            }
        };
    });
