/* Source: src/mt-includes/widgets/tabs/src/frontend/tabs.frontend.js*/
angular.module('website.widget.tabs').directive('motoWidgetTabs', [
    'website.WidgetsRepository',
    function(WidgetsRepository) {
        var animationPreferences = {
            startAnimation: 'onArriving'
        };

        return {
            restrict: 'C',
            scope: true,
            compile: function($element) {
                var thisWidget = WidgetsRepository.registry($element);

                return {
                    pre: function($scope) {
                        thisWidget.setScope($scope);
                    },
                    post: function() {
                        $element.motoTabs({
                            onOpened: function(item) {
                                thisWidget.getChild(item.id).onArriving(animationPreferences);
                                thisWidget.getChild(item.id).onArrived(animationPreferences);
                            },
                            onClosed: function(item) {
                                thisWidget.getChild(item.id).onVanishing();
                                thisWidget.getChild(item.id).onVanished();
                            }
                        });
                    }
                };
            }
        };
    }]);
