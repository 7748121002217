/* Source: src/mt-includes/widgets/counter/src/frontend/moto_counter.jquery.plugin.js*/
(function($) {
    $.fn.motoCounter = function(options) {
        var $element;
        var valueElement;
        var settings;
        var defaultOptions = {
            countFrom: 0,
            countTo: 100,
            duration: 3,
            easing: 'easeOutCirc',
            complete: null,
            elementSelector: '.moto-widget-counter__value'
        };

        if (!options || !angular.isObject(options)) {
            return false;
        }

        // From jquery easing https://api.jqueryui.com/easings/
        jQuery.easing['easeOutCirc'] = function(x, t, b, c, d) {
            return c * Math.sqrt(1 - (t = t / d - 1) * t) + b;
        };

        settings = $.extend({}, defaultOptions, options);

        $element = $(this);
        valueElement = $element.find(settings.elementSelector);

        // stop previous animation
        $element.stop();

        $({
            count: settings.countFrom
        }).animate({
            count: settings.countTo
        }, {
            duration: settings.duration * 1000,
            easing: settings.easing,
            step: function() {
                var mathCount = Math.ceil(this.count);

                valueElement.text(mathCount);
            },
            complete: function() {
                valueElement.text(settings.countTo);
            }
        });
    };

}(jQuery));
