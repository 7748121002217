/* Source: src/mt-includes/widgets/counter/src/frontend/frontend.js*/
angular.module('website.widget.counter', ['website']).directive('motoWidgetCounter', [
    'motoBeforeInViewport',
    function(motoBeforeInViewport) {
        return {
            restrict: 'C',
            scope: {
                options: '<motoCounterOptions'
            },
            link: function($scope, $element) {
                motoBeforeInViewport.startWatching({
                    element: $element,
                    onEnter: function() {
                        $element.motoCounter($scope.options);
                    }
                });
            }
        };
    }
]);
