/* Source: src/mt-includes/js/src/moto_link/initLightboxGalleryDirective.js*/
/**
 * Directive for automation initializing our lightbox gallery.
 *
 * For correct working with caption you have to mark two nodes:
 *     [data-moto-lightbox-item] - node that wrap every item in gallery
 *     [data-moto-lightbox-caption] - node that contain caption of item
 */

angular.module('website.moto_link')
    .directive('motoInitLightboxGallery', function() {
        var DEFAULT_PROPERTIES = {
            itemRootSelector: '[data-moto-lightbox-item]',
            itemLinkSelector: '[data-moto-lightbox-link]',
            itemCaptionSelector: '[data-moto-lightbox-caption]',
            itemCaptionClassDataAttr: 'data-moto-lightbox-caption-class'
        };

        return {
            restrict: 'A',
            link: function($scope, $element, $attrs) {
                $element.magnificPopup({
                    delegate: 'a[data-action=lightbox]' + DEFAULT_PROPERTIES.itemLinkSelector,
                    type: 'image',
                    tClose: '',
                    tLoading: '',
                    mainClass: angular.isDefined($attrs.lightboxGalleryShowCounter) ? '' : 'moto-lightbox_hidden-counter',
                    autoFocusLast: false,
                    gallery: {
                        enabled: true,
                        preload: [5, 10],
                        tPrev: '',
                        tNext: '',
                        tCounter: '%curr% / %total%'
                    },
                    image: {
                        titleSrc: function(item) {
                            var captionNode = angular.element(item.el.context)
                                .closest(DEFAULT_PROPERTIES.itemRootSelector)
                                .find(DEFAULT_PROPERTIES.itemCaptionSelector)
                                .clone();
                            var captionClass = captionNode.attr(DEFAULT_PROPERTIES.itemCaptionClassDataAttr);

                            if (captionClass) {
                                // remove all existing moto-text_* class names
                                captionNode.find(('[class*="moto-text_"]')).each(function() {
                                    this.className = this.className.replace(/moto-text_[^\s]+/g, captionClass);
                                });
                                captionNode = captionNode.wrapInner('<div class=' + captionClass + '></div>');
                            }
                            captionNode = captionNode.wrapInner('<div class="moto-widget-text"></div>');

                            return captionNode.html() || '';
                        }
                    },
                    zoom: {
                        enabled: true,
                        duration: 400,
                        easing: 'ease-in-out'
                    }
                });
            }
        };
    });
