/* Source: src/mt-includes/widgets/completion_bar_circular/frontend.js*/
angular.module('website.widget.completion_bar_circular', ['website']).directive('motoWidgetCompletionBarCircular', [
    'motoBeforeInViewport',
    function(motoBeforeInViewport) {
        return {
            restrict: 'C',
            scope: {
                options: '<motoCompletionBarCircularOptions'
            },
            link: function($scope, $element) {
                if (!$scope.options.progress.animation && !$scope.options.progressBar.animation) {
                    return;
                }

                motoBeforeInViewport.startWatching({
                    element: $element,
                    onEnter: function() {
                        if ($scope.options.progress.animation) {
                            $element.motoCounter({
                                countTo: $scope.options.progress.custom ? $scope.options.progress.customValue : $scope.options.progress.value,
                                duration: $scope.options.progress.animationDuration,
                                elementSelector: '.moto-widget-completion_bar_circular__progress-value'
                            });
                        }
                        if ($scope.options.progressBar.animation) {
                            // you should use native classList method for svg elements
                            $element.find('.moto-widget-completion_bar_circular__svg').get(0).classList.add('moto-widget-completion_bar_circular__svg-animated');
                        }
                    }
                });
            }
        };
    }
]);
