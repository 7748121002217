/* Source: src/mt-includes/js/src/moto_link/go_to_page.js*/
angular.module('website.moto_link').run([
    function() {
        function getUrlWithoutHash(url) {
            var hashIndex = url.indexOf('#');

            if (hashIndex === -1) {
                return url;
            }

            return url.substring(0, hashIndex);
        }
        function getUrlHash(url) {
            var hashIndex = url.indexOf('#');

            if (hashIndex === -1) {
                return null;
            }

            return url.substring(hashIndex + 1, url.length);
        }
        $('body').on('click', '.moto-link[data-action=page]', function(event) {
            if ($(event.currentTarget).parents('.mfp-content').length &&
                (getUrlWithoutHash(window.location.href) === getUrlWithoutHash(event.currentTarget.href)) &&
                (getUrlHash(event.currentTarget.href) && !$('.mfp-content').find('a.moto-anchor[name=' + getUrlHash(event.currentTarget.href) + ']').length)) {
                $.magnificPopup.close();
            }

            return event;
        });
    }]);
