/* Source: src/mt-includes/js/src/moto_relative_sticky/moto_relative_sticky.js*/
/**
 * @module website
 *
 * MotoRelativeSticky directive
 */

angular.module('website').directive('motoRelativeSticky', [
    function() {
        var STICKY_CLASS = 'moto-relative-sticky';

        return {
            restrict: 'A',
            link: function($scope, $element, $attrs) {
                $element.addClass(STICKY_CLASS);
                $element.css('top', $attrs.motoRelativeStickySpacing || 0);
            }
        };
    }]);
