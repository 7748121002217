/* Source: src/mt-includes/widgets/advanced_image/frontend.js*/
angular.module('website.widget.advanced_image', ['website.core'])
.directive('motoWidgetAdvancedImagePicture', [
    'website.Entertainment',
    function(Entertainment) {
        return {
            restrict: 'AC',
            link: function($scope, $element) {
                var entertainmentEnv = {
                    $scope: $scope,
                    $element: $element
                };

                Entertainment.$onLetsDanceEvent(entertainmentEnv, function() {
                    $element.motoAbsolutePositionCover($element.find('.moto-widget-advanced-image__picture-element'));
                });
            }
        };
    }]);
